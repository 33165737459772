<template>
    <div class="layout-task-add">
        <div class="layout-device-bread">
            <div class="list-back"><a href="javascript:" @click="onBack"><Icon type="ios-log-out" size="20"/>退出</a></div>
        </div>
        <div class="layout-device-li" :style="'height:'+editTaskHeight+'px'">
            <div class="box">
                <ul>
                    <li class="item">
                        <span class="label required">任务分类：</span>
                        <Cascader v-if="taskTypeListArray" style="width: 300px" :data="taskTypeListArray" v-model="taskParams.classificationId"></Cascader>
                    </li>
                    <li class="item">
                        <span class="label required">任务名称：</span>
                        <Input v-model="taskParams.tasksName" placeholder="请输入" clearable style="width: 300px" />
                    </li>
                    <li class="item">
                        <span class="label required">引用流程：</span>
                        <Select v-model="taskParams.flowId" :loading="flowLoading" filterable clearable @on-open-change="onOpenChange" style="width: 300px">
                            <Option v-for="item in workflowListArray" :value="item.id" :key="item.id">{{ item.flowName }}</Option>
                        </Select>
                    </li>
                    <li class="item">
                        <span class="label">执行人：</span>
<!--                        <div class="assign-box" :class="taskParams.assignUserName !== ''?'assign-hover':''">-->
<!--                            <Input v-model="taskParams.assignUserName" readonly icon="ios-arrow-down" @click.native="onAssignClick" placeholder="请选择" style="width: 300px" />-->
<!--                            <a href="javascript:" class="item-close" v-if="taskParams.assignUserName !== ''" @click.stop="taskParams.assignUserId = '';taskParams.assignUserName=''"><Icon type="ios-close-circle"/></a>-->
<!--                        </div>-->
                        <Select v-model="taskParams.assignUserId" :loading="assignLoading" multiple filterable @on-open-change="onSearchClick" style="width: 300px">
                            <Option v-for="item in assignArray" :value="item.value" :key="item.value"><Avatar :src="item.profilePictureUrl?item.profilePictureUrl:defaultAvatar" size="small" />{{ item.label }}</Option>
                        </Select>
                    </li>
                    <li class="item">
                        <div class="task-modal-switch">
                            设置每次执行任务均从头开始
                            <div class="btn-switch">
                                <onSwitch v-model="taskParams.anew" :true-value="1" :false-value="0" size="small"></onSwitch>
                            </div>
                        </div>
                    </li>
<!--                    <li class="item">-->
<!--                        <span class="label required">生产厂家：</span>-->
<!--                        <Select v-model="newlyParams.manufacturer" clearable style="width:300px">-->
<!--                            <Option v-for="(manufacturer,key) in manufacturerArray" :value="manufacturer.id" :key="key">{{ manufacturer.name }}</Option>-->
<!--                        </Select>-->
<!--                        <a href="javascript:" class="add" @click="manufacturerVisible = true"><Icon custom="icon-manage" style="margin-right: 4px"/>管理生产厂家</a>-->
<!--                    </li>-->
                    <li class="item task-approve">
                        <h1 class="approve-title">依此审批</h1>
                        <div class="approve-list">
                            <div class="approve-steps-item" :key="key" v-for="(approval,key) in taskParams.approvalUserIds">
                                <div class="steps-tail"></div>
                                <div class="steps-head"><a href="javascript:" @click="onRemoveApproval(key)"><Icon type="md-remove-circle" size="20"/></a></div>
                                <div class="steps-main">
                                    <div class="steps-title">{{ key+1 }}级审批人</div>
                                    <div class="steps-content">
                                        <a href="javascript:" class="steps-add"  @click="onAssignClick(key)" v-if="approval.id === ''"><Icon type="md-add" size="20"/></a>
                                        <div class="steps-name-box" v-if="approval.id !== ''">
                                            <span class="avatar" :style="'background-image:url('+(approval.profilePicture === ''?defaultAvatar:approval.profilePicture)+')'"></span>
                                            <div class="text">
                                                <span class="name">{{ approval.userName }}</span>
                                                <span class="tel">{{ approval.mobile }}</span>
                                                <a href="javascript:" class="clear" @click="onClearUser(key)"><Icon type="ios-close-circle" size="20"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="approve-steps-item">
                                <div class="steps-head"><a href="javascript:" @click="onAddApproval"><Icon type="ios-add-circle-outline" size="20" /></a></div>
                                <div class="steps-main">
                                    <div class="steps-title blue"><a href="javascript:" @click="onAddApproval">添加审批流程</a></div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="item layout-device-btn">
                        <Button type="primary" :loading="editLoading" @click="onSubmitCheck(1)">立即发布</Button>
                        <Button type="primary" :loading="editLoading" ghost @click="onSubmitCheck(0)">稍后发布</Button>
                        <Button @click="onBack">取 消</Button>
                    </li>
                </ul>
            </div>
        </div>

        <!--s: assign modal-->
        <Modal
            v-model="assignVisible"
            title="选择审批人"
            :width="428"
            class-name="task-modal-box"
            footer-hide>
            <div class="assign-modal">
                <div class="assign-search">
                    <Input v-model="assignValue" prefix="ios-search-outline" placeholder="搜索" @on-keyup="onAssignSearch"/>
                </div>
                <div class="assign-list">
                    <ul>
                        <li :key="key" v-for="(assignList,key) in assignSelectArray" :class="assignList.select?'active':''" @click="onAssign(assignList,key)">
                            <span class="avatar" :style="'background-image:url('+(assignList.profilePictureUrl === ''?defaultAvatar:assignList.profilePictureUrl)+')'"></span>
                            <div class="user-info">{{ assignList.label }} <font>{{ assignList.phone }}</font></div>
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
       <!--e: assign modal-->
<!--       <Spin fix v-if="flowLoading"></Spin>-->
    </div>
</template>
<script>
import EditTask from './editTask'
export default EditTask
</script>
<style lang="less">
@import "editTask";
</style>
