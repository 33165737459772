/**
 * 任务 新增
 * @type {{}}
 */
import Vue from "vue";
import {
    Message,
    Modal,
    Select,
    Option,
    Input,
    Icon,
    Button,
    Cascader,
    Switch,
    Spin,
} from "view-design";
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Button', Button);
Vue.component('Cascader', Cascader);
Vue.component('Modal', Modal);
Vue.component('onSwitch', Switch);
Vue.component('Spin', Spin);
import defaultAvatar from '@/assets/Image/default-avatar.png'
import { mapMutations, mapState } from "vuex";
import {
    getTaskClassAllService,
    getWorkflowSimpleListService,
    getAssignUserListService,
    editTaskListService
} from "@/service/workflow-service";
const EditTask = {
    name:'EditTask',
    computed: {
        ...mapState({
            taskType: state => state.system.taskType, //任务分类
            taskEdit: state => state.system.taskEdit,
        }),
    },
    data() {
        return {
            taskTypeListArray:'', //分类列表数据
            workflowListArray:[], //流程列表
            defaultAvatar:defaultAvatar,
            assignArray:[],
            assignSelectArray:[],
            assignValue:'',
            flowLoading:false,
            assignLoading:false,
            assignVisible:false,
            editLoading:false,
            approvalKey:0,
            editTaskHeight:0,
            taskParams:{
                id:'',
                classificationId:[], //任务分类
                tasksName:'', //任务名称
                flowId:'', //引用流程
                anew:0, //执行任务状态
                status:'', //发布状态
                assignUserId:[], //用户指派的id
                assignUserName:'', //用户指派的名称
                approvalUserIds:[{
                    id:'',
                    userName:'',
                    profilePicture:'',
                    mobile:'',
                }],
            },
        }
    },
    created() {
        this.onTaskClassAllService().then()
        if(this.taskEdit!==''){
            this.onWorkflowList().then()
            this.onAssignUserListService().then()
            console.log(this.taskEdit)
            this.taskParams.id = this.taskEdit.id
            this.taskParams.classificationId = this.taskEdit.classificationId
            this.taskParams.tasksName = this.taskEdit.tasksName
            this.taskParams.flowId = this.taskEdit.flowId
            this.taskParams.anew = this.taskEdit.anew
            this.taskParams.status = this.taskEdit.id
            if(this.taskEdit.approvalUsers.length!==0){
                this.taskParams.approvalUserIds = JSON.parse(JSON.stringify(this.taskEdit.approvalUsers))
            }
            if(this.taskEdit.executorUsers.length!==0){
                this.onAssignUserListService().then()
                for(let i in this.taskEdit.executorUsers){
                    this.taskParams.assignUserId.push(this.taskEdit.executorUsers[i].id)
                }
            }
        }
        this.onEditTaskHeight()
    },
    mounted() {
        window.addEventListener('resize', this.onEditTaskHeight)
    },
    methods: {
        ...mapMutations('system',['SET_DEVICE_INFO','SET_TAB_ID']),
        /**
         * 计算table高度
         */
        onEditTaskHeight(){
            this.editTaskHeight = window.innerHeight - 115
        },
        /**
         * 返回
         */
        onBack() {
            this.$router.go(-1)
        },
        /**
         * 添加审批人
         */
        onAddApproval(){
            let resultRegular = {}
            for(let i in this.taskParams.approvalUserIds){
                if(this.taskParams.approvalUserIds[i].id === ''){
                    resultRegular = {not:true,number:Number(i)+1}
                }
            }
            if(resultRegular.not){
                Message.warning('请添加'+resultRegular.number+'级审批人')
            }else{
                this.taskParams.approvalUserIds.push({
                    id:'',
                    userName:'',
                    profilePicture:'',
                    mobile:'',
                })
            }
        },
        /**
         * 删除审批人
         * @param key
         */
        onRemoveApproval(key){
            if(key!=0 || (key===0 && this.taskParams.approvalUserIds.length >1)){
                Modal.confirm({
                    title: '提示',
                    content: '<p>您确定要删除'+(key+1)+'级审批人吗？</p>',
                    onOk: () => {
                        for(let i in this.assignSelectArray){
                            if(this.assignSelectArray[i].value ===  this.taskParams.approvalUserIds[key].id){
                                this.assignSelectArray[i].select = false
                            }
                        }
                        this.taskParams.approvalUserIds.splice(key,1)
                    }
                });
            }else{
                Message.warning('1级审批人为默认不可删除！')
            }
        },
        /**
         * 过滤列表相同名称
         * @param array
         * @param content
         * @returns {*[]}
         */
        onFilter(array,content){
            return array.filter((value)=>{  //过滤数组元素
                return value.label.includes(content) || value.phone.includes(content); //如果包含字符返回true
            });
        },
        /**
         * 搜索执行人
         */
        onAssignSearch() {
            if(this.assignValue === '') {
                this.assignSelectArray = this.assignArray
                return
            }
            this.assignSelectArray = this.onFilter(this.assignArray,this.assignValue)
        },
        /**
         * 打开工作流程
         * @param status
         */
        onOpenChange(status){
            if(status && this.workflowListArray.length === 0){
                this.onWorkflowList().then()
            }
        },
        onSearchClick(){
            if(this.assignArray.length === 0){
                this.onAssignUserListService().then()
            }
        },
        /**
         * 打开指派列表
         * @param key
         */
        onAssignClick(key){
            this.assignVisible = true
            this.approvalKey = key
            if(this.assignArray.length === 0){
                this.onAssignUserListService().then()
            }
        },
        /**
         * 提交验证
         */
        onSubmitCheck(status) {
            if(this.taskParams.classificationId.length === 0){
                Message.warning('请选择任务分类！');
                return;
            }
            if(this.taskParams.tasksName === ''){
                Message.warning('请填写任务名称！');
                return;
            }
            if(this.taskParams.flowId === ''){
                Message.warning('请选择引用流程！');
                return;
            }
            let resultRegular = this.taskParams.approvalUserIds.some(item =>{
                if(item.id === ''){
                    return true
                }
            })
            if(this.taskParams.approvalUserIds.length>1 && resultRegular){
                Message.warning('您有未添加的审批人，请前往添加！');
                return
            }
            this.taskParams.status = status
            this.onEditTaskList().then()
        },
        /**
         * 选择指派人
         * @param obj
         * @param key
         */
        onAssign(obj,key){
            // let resultRegular = this.approvalArray.some(item =>{
            //     if(item === obj.id){
            //         return true
            //     }
            // })
            // if(resultRegular){
            //     return
            // }
            // this.approvalArray.push(obj.id)
            if(this.assignSelectArray[key].select){
                return
            }
            this.assignSelectArray[key].select = true
            this.taskParams.approvalUserIds[this.approvalKey].id = obj.value
            this.taskParams.approvalUserIds[this.approvalKey].userName = obj.label
            this.taskParams.approvalUserIds[this.approvalKey].profilePicture = obj.profilePictureUrl
            this.taskParams.approvalUserIds[this.approvalKey].mobile = obj.phone
            this.assignVisible = false
        },
        /**
         * 删除审批人信息
         * @param key
         */
        onClearUser(key){
            // for(let i in this.approvalArray){
            //     if(this.approvalArray[i] === this.taskParams.approvalUserIds[key].id){
            //         this.approvalArray.splice(i,1)
            //     }
            // }
            for(let i in this.assignSelectArray){
                if(this.assignSelectArray[i].value ===  this.taskParams.approvalUserIds[key].id){
                    this.assignSelectArray[i].select = false
                }
            }
            this.taskParams.approvalUserIds[key].id = ''
            this.taskParams.approvalUserIds[key].userName = ''
            this.taskParams.approvalUserIds[key].profilePicture = ''
            this.taskParams.approvalUserIds[key].mobile = ''
        },
        /**
         * 替换属性名
         * @param data
         */
        onReplaceAttribute(data){
            let item = []
            data.map(list => {
                let newData = {}
                newData.label = list.name;
                newData.value = list.id;
                newData.visible = list.visible;
                if(list.childNode){
                    newData.children = this.onReplaceAttribute(list.childNode)
                }
                item.push(newData)
            })
            return item
        },
        /**
         * 替换执行人属性名
         * @param data
         */
        onExecutorAttribute(data){
            let item = []
            data.map(list => {
                let newData = {}
                newData.label = list.name;
                newData.value = list.id;
                newData.child = list.child;
                newData.phone = list.phone;
                newData.profilePictureUrl = list.profilePictureUrl;
                newData.type = list.type;
                newData.select = false
                item.push(newData)
            })
            return item
        },
        /**
         * 获取所有设备分类
         */
        async onTaskClassAllService() {
            try {
                let params = {
                    tasksType:this.taskType
                }
                const { code,data } = await getTaskClassAllService(params)
                if(code === 'SUCCESS'){
                    this.taskTypeListArray = data
                    for(let i in data){
                        data[i].visible = true

                        if(data[i].childNode.length!==0){
                            for(let j in data[i].childNode){
                                data[i].childNode[j].visible = true
                            }
                        }
                    }
                    this.taskTypeListArray = this.onReplaceAttribute(data)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取工作流列表
         */
        async onWorkflowList() {
            try {
                this.flowLoading = true
                this.workflowListArray = []
                const { code,data } = await getWorkflowSimpleListService()
                if(code === 'SUCCESS'){
                    for(let i in data){
                        if(data[i].state === 1){
                            this.workflowListArray.push(data[i])
                        }
                    }
                    console.log(this.workflowListArray)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.flowLoading = false
            }catch (error){
                this.flowLoading = false
                console.log(error)
            }
        },
        /**
         * 获取指派列表
         */
        async onAssignUserListService() {
            try {
                this.assignLoading = true
                const { code,data } = await getAssignUserListService()
                if(code === 'SUCCESS'){
                    this.assignArray = this.onExecutorAttribute(data)
                    this.assignSelectArray = this.onExecutorAttribute(data)
                    for(let i in this.taskParams.approvalUserIds){
                        for(let j in this.assignSelectArray){
                            if(this.taskParams.approvalUserIds[i].id === this.assignSelectArray[j].value){
                                this.assignSelectArray[j].select = true
                            }
                        }
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.assignLoading = false
            }catch (error){
                this.assignLoading = false
                console.log(error)
            }
        },
        /**
         * 编辑任务
         */
        async onEditTaskList() {
            try {
                this.editLoading = true
                let typeId
                if(this.taskParams.classificationId.length === 1){
                    typeId = this.taskParams.classificationId[0]
                }
                if(this.taskParams.classificationId.length === 2){
                    typeId = this.taskParams.classificationId[1]
                }
                if(this.taskParams.classificationId.length === 3){
                    typeId = this.taskParams.classificationId[2]
                }
                let params = {
                    id:this.taskParams.id,
                    classificationId:typeId,
                    tasksName:this.taskParams.tasksName,
                    flowId:this.taskParams.flowId,
                    status:this.taskParams.status,
                    tasksType:this.taskType,
                    assignUserIds:this.taskParams.assignUserId,
                    anew:this.taskParams.anew,
                    approvalUserIds:this.taskParams.approvalUserIds.length === 1 && this.taskParams.approvalUserIds[0].id === ''?[]:this.taskParams.approvalUserIds
                }
                const { code } = await editTaskListService(params)
                if(code === 'SUCCESS'){
                    Message.success('操作成功！')
                    this.onBack()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.editLoading = false
            }catch (error){
                this.editLoading = false
                console.log(error)
            }
        },
    },
    destroyed () {
        window.removeEventListener('resize',this.onEditTaskHeight)
    }
}
export default EditTask
